<template>
    <span v-html="parsedContent"></span>
</template>

<script>
export default {
    name: "Markdown"
}
</script>
<script setup>
import {parseInline} from "marked"
import {computed} from "vue";
import DOMPurify from 'dompurify';

const props = defineProps({
    content: {
        type: String,
        required: true,
    },
});

const parsedContent = computed(() => {
    return DOMPurify.sanitize(parseInline(props.content, {}));
});
</script>

<style scoped>

</style>
