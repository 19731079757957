<template>
    <div class="d-flex justify-content-center mb-2">
        <template v-if="pagination.to && pagination.last_page !== 1">
            <ul class="pagination pagination-rounded mb-0">
                <template v-for="(link, index) in pagination.links" :key="index">
                    <li v-bind:class="{ 'page-item': true, 'active': link.active }">
                        <a
                            v-html="link.label"
                            :class="link.url ?? 'disabled'"
                            @click.prevent="emitPageChanged(link)"
                            class="page-link"
                            href="javascript:void(0)"
                        ></a>
                    </li>
                </template>
            </ul>
        </template>
    </div>
    <div class="d-flex justify-content-center">
        <div>
            Showing {{ recordName }} from {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }}
        </div>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'

const props = defineProps({
    recordName: {
        type: String,
        required: true,
    },
    pagination: {
        type: Object,
        required: true
    },
})

const emit = defineEmits(['pageChanged'])

const emitPageChanged = (link) => {
    emit('pageChanged', {
        url: link.url,
        page: getPageFromUrl(link.url)
    })
}

const getPageFromUrl = (url) => {
    if (url) {
        return url.split('page=')[1]
    }
}

</script>