<template>
    <div
        :class="[
            props.type === 'border' ? 'spinner-border' : 'spinner-grow',
            'text-' + props.color,
            props.size === 'small' ? 'spinner-border-sm' : '',
        ]"
        role="status"
    >
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script>
export default {
    name: "Spinner"
}
</script>
<script setup>
const props = defineProps({
    type: {
        type: String,
        default: 'grow',
    },
    color: {
        type: String,
        default: 'secondary',
    },
    size: {
        type: String,
        default: 'normal',
    },
})
</script>

<style scoped>

</style>
