<template>
    <div class="d-inline-block">
        <template v-if="avatar">
            <img :class="['rounded-circle'].concat(sizeClasses)" :src="asset(avatar)" :alt="name" />
        </template>
        <template v-else>
            <div :class="['d-inline-flex', 'align-items-center', 'justify-content-center', 'rounded-circle'].concat(sizeClasses, initialsColor)">
                <span class="font-medium leading-none">
                    {{ initials }}
                </span>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Avatar",
    props: {
        avatar: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: 'medium',
        }
    },
    computed: {
        sizeClasses: function () {
            switch (this.size) {
                case 'extra-small':
                    return ['h-6', 'w-6', 'text-xs']
                case 'small':
                    return ['h-8', 'w-8', 'text-sm']
                case 'large':
                    return ['h-12', 'w-12', 'text-lg']
                case 'extra-large':
                    return ['h-14', 'w-14', 'text-xl']
                case 'full':
                    return ['h-28', 'w-28', 'fs-1']
                default:
                    return ['h-10', 'w-10']
            }
        },
        initials: function () {
            let names = this.name.split(' ')
            let initials = names[0].substring(0, 1).toUpperCase()

            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase()
            }

            return initials;
        },
        initialsColor: function () {
            switch (true) {
                case this.initials < 'D':
                    return ['bg-primary', 'text-white']
                case this.initials < 'J':
                    return ['bg-secondary', 'text-white']
                case this.initials < 'P':
                    return ['bg-success', 'text-dark-green']
                case this.initials < 'V':
                    return ['bg-warning', 'text-dark']
                default:
                    return ['bg-info', 'text-dark']
            }
        }
    },
}
</script>

<style scoped>

</style>
