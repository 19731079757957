<template>
    <button type="button" class="btn" :disabled="loading || disabled" :class="classObject">
        <div class="d-flex justify-content-center align-items-center mx-auto">
            <div v-if="loading" class="spinner-border spinner-border-sm me-2" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <SvgIcon
                v-if="icon && !loading"
                :class="[size === 'small' ? 'h-4 w-4' : 'h-5 w-5']"
                :path="icon"
                class="me-1"
                type="mdi"
                :size="iconSize"
            ></SvgIcon>
            <template v-if="loading && labelWhenLoading">{{ labelWhenLoading }}</template>
            <template v-else>
                <slot></slot>
            </template>
        </div>
    </button>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
export default {
    name: "Button",
    components: {
        SvgIcon,
    },
    props: {
        color: {
            required: false,
            type: String,
            default: 'primary',
        },
        size: {
            required: false,
            type: String,
            default: 'medium',
        },
        iconSize: {
            required: false,
            type: String,
            default: '14',
        },
        loading: {
            required: false,
            type: Boolean,
            default: false,
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        icon: {
            required: false,
        },
        classArray: {
            required: false,
            type: Array,
            default() {
                return []
            },
        },
        labelWhenLoading: {
            required: false,
            type: String,
            default: null,
        },
    },
    computed: {
        classObject: function () {
            let classes = {
                'btn-primary': this.color === 'primary',
                'btn-outline-primary': this.color === 'outline-primary',
                'btn-danger': this.color === 'danger',
                'btn-outline-danger': this.color === 'outline-danger',
                'btn-secondary': this.color === 'secondary',
                'btn-light': this.color === 'light',
                'btn-warning': this.color === 'warning',
                'btn-outline-secondary': this.color === 'outline-secondary',
                'btn-success': this.color === 'success',
                'btn-info': this.color === 'info',
                'btn-white': this.color === 'white',
                'btn-link': this.color === 'link',
                'btn-link text-danger': this.color === 'danger-link',
                'btn-link text-secondary': this.color === 'secondary-link',
                'btn-xs': this.size === 'extra-small',
                'btn-sm': this.size === 'small',
                'btn-lg': this.size === 'large',
                'btn-xl': this.size === 'extra-large',
                'd-inline-flex': this.loading,
                'align-items-center': this.loading,
            }

            this.classArray.forEach((className) => {
                classes[className] = true
            })

            return classes
        },
        loadingColor: function () {
            return (this.color === 'white') ? 'text-gray-500' : 'text-white'
        }
    }
}
</script>
